import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAllPairNames } from "../../state/pairnames";
import { selectAllExchangeNames } from "../../state/exchangenames";
import { useState } from "react";

const Header = () => {
  const exchangeNames = useSelector(selectAllExchangeNames);
  const pairNames = useSelector(selectAllPairNames);
  const [open, setOpen] = useState(false);

  return (
    <>
      <header className="bg-anta-dark text-white p-2 text-center flex items-center">
        <div className="container mx-auto flex justify-center max-w-screen-xl">
          <h3 className="text-sm">
            Currencies : {pairNames.length.toString()} | References :{" "}
            {exchangeNames.length.toString()}
          </h3>
        </div>
      </header>

      <header className="bg-white text-center border-b-2 flex items-center">
        <div className="container mx-auto flex justify-between items-center max-w-screen-xl">
          <div id="logo" className="text-left">
            <Link to="/">
              <img
                src="./logo.png"
                alt="Logo"
                style={{ width: "180px", height: "38px" }}
              />
            </Link>
          </div>

          <nav className="hidden md:flex space-x-4">
            <Link to="/" className="hover:bg-anita hover:text-gray-800 p-4">
              Currencies
            </Link>
            <Link
              to="/references"
              className="hover:bg-anita hover:text-gray-800 p-4"
            >
              References
            </Link>
            <Link
              to="/contact"
              className="hover:bg-anita hover:text-gray-800 p-4"
            >
              Contact
            </Link>
          </nav>

          <div className="md:hidden">
            {!open && (
              <button
                onClick={() => setOpen(!open)}
                className="focus:outline-none mr-2"
              >
                
                <svg
                  className="w-7 h-7"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_429_11066)">
                    <path
                      d="M3 6.00092H21M3 12.0009H21M3 18.0009H21"
                      stroke="#292929"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_429_11066">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.000915527)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            )}
          </div>
        </div>
      </header>

      {/* Side Menu */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-gray-50 text-gray-800 transform ${
          open ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden z-50`}
      >
        <div className="flex flex-row justify-center items-center p-4 border-b border-gray-700">
          <div className="text-center">
            <Link to="/">
              <img
                src="./logo.png"
                alt="Logo"
                style={{ width: "180px", height: "38px" }}
              />
            </Link>
          </div>
          <button onClick={() => setOpen(false)} className="focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <nav className="flex flex-col space-y-4">
          <Link to="/" className="hover:bg-blue-100  hover:text-gray-800 p-4">
            Currencies
          </Link>
          <Link
            to="/references"
            className="hover:bg-blue-100 hover:text-gray-800 p-4"
          >
            References
          </Link>
          <Link
            to="/contact"
            className="hover:bg-blue-100 hover:text-gray-800 p-4"
          >
            Contact
          </Link>
        </nav>
      </div>
    </>
  );
};

export default Header;
