import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
const NoPage = () => {
  return (
    <>
                  <Helmet>
                
                <title>{`404 Error - Riazoom.com`}</title>
                  <meta
                    name="description"
                    content="Discover the best exchange rates with our real-time currency comparison tool. Compare live prices across multiple platforms and save money on every transaction."
                  />
                  <meta
                    name="keywords"
                    content="real-time currency comparison, currency prices, exchange rates, currency comparison tool, best exchange rates, rial price"
                  />
                  <meta name="robots" content="index, follow" />
                
                  <link rel="canonical" href="https://www.rialzoom.com" />
                </Helmet>
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-10 rounded shadow-md text-center">
        <h1 className="text-6xl font-bold text-red-500">404</h1>
        <p className="text-2xl mt-4">Page Not Found</p>
        <p className="mt-2 text-gray-600">Sorry, the page you are looking for does not exist.</p>
        <Link to="/" className="mt-6 inline-block bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition">
          Go Home
        </Link>
      </div>
    </div>
      </>
  );
};
  
  export default NoPage;
  