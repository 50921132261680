import { BrowserRouter } from "react-router-dom";
import "./App.css";


import ReactGA from "react-ga4";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AppRouter from "./AppRouter";

const GAC = process.env.REACT_APP_G_C;
ReactGA.initialize([{ trackingId: GAC! }]);
ReactGA.send(window.location.pathname + window.location.search);

function App() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Real-Time Currency Comparison: Explore and Compare Real Prices Across Various Platforms</title>
          <meta name="description" content="Discover the best exchange rates with our real-time currency comparison tool. Compare live prices across multiple platforms and save money on every transaction." />
          <meta name="keywords" content="real-time currency comparison, currency prices, exchange rates, currency comparison tool, best exchange rates" />
          <meta name="robots" content="index, follow" />
          <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.css" rel="stylesheet" />
          <link rel="canonical" href="https://www.rialzoom.com" />
        </Helmet>
        <BrowserRouter>
        <AppRouter></AppRouter>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
