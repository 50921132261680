import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { getExchangeNames, getSymbolData } from "../services/maindata";
import { IExchangeNames, IPairNames, ISymbolNames } from "../common/types";
import "flatpickr/dist/flatpickr.css";
import Flatpickr from "react-flatpickr";
import { Button } from "flowbite-react";
import { PuffLoader } from "react-spinners";
import { MultiValue } from "react-select";
import { Option } from "../common/types";
import { Helmet } from "react-helmet-async";
export interface SymbolChartProps {
  symbol: ISymbolNames;
  selectedRefrences: MultiValue<Option>;
}

interface ISeries {
  name: string;
  data: number[];
}

const SymbolChart: React.FC<SymbolChartProps> = ({
  symbol,
  selectedRefrences,
}) => {
  const location = useLocation();
  const [selectedSymbol, setSelectedSymbol] = useState<ISymbolNames>(symbol);
  const [selectedPairname, setSelectedPairname] = useState<IPairNames>();
  const [selectedReferences, setSelectedReferences] =
    useState<MultiValue<Option>>(selectedRefrences);

  const [seriesData, setSeriesData] = useState<ISeries[]>([]);
  const [timeDatesSeries, setTimeDatesSeries] = useState<string[]>([]);

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      setSelectedSymbol(location.state.symbol);
      setSelectedReferences(location.state.selectedReferences);
    }
  }, [location.state]);

  useEffect(() => {
    if (selectedSymbol._id !== "" && selectedReferences) {
      fetchData(selectedSymbol._id, startDate, endDate);
    }
  }, [selectedSymbol, selectedReferences]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      timeZone: "UTC",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const fetchData = async (symbolname: string, start: Date, end: Date) => {
    setIsLoading(true);
    const references: string[] =
      selectedReferences.map((ref) => ref.value!) || [];

    const data = await getSymbolData(symbolname, start, end, references);
    const exchangeMap: {
      [key: string]: { data: number[]; timeDates: string[] };
    } = {};
    if (data.length > 0) {
      setSelectedPairname(data[0].pair);
      data.forEach((item: any) => {
        const exchangeName = item.exchangeName?.name;
        if (!exchangeName) return;

        if (!exchangeMap[exchangeName]) {
          exchangeMap[exchangeName] = { data: [], timeDates: [] };
        }

        exchangeMap[exchangeName].data.push(item.sellPrice);
        exchangeMap[exchangeName].timeDates.push(formatDate(item.timeDate));
      });
      const dataseries: ISeries[] = [];
      const timeSeriesByExchange: { [key: string]: string[] } = {};

      for (const [name, { data, timeDates }] of Object.entries(exchangeMap)) {
        dataseries.push({ name, data: data.reverse() });
        timeSeriesByExchange[name] = timeDates.reverse();
      }

      const firstExchangeName = Object.keys(timeSeriesByExchange)[0];
      const firstExchangeTimeDates = timeSeriesByExchange[firstExchangeName];
      setSeriesData(dataseries);
      setTimeDatesSeries(firstExchangeTimeDates);
      setIsLoading(false);
    }
  };

  const handleApply = () => {
    fetchData(selectedSymbol!._id, startDate, endDate);
  };

  const options: any = {
    chart: {
      height: 550,
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    title: {
      text: selectedSymbol.name,
      align: "left",
    },
    legend: {
      tooltipHoverFormatter: function (val: any, opts: any) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: timeDatesSeries,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val: any) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (val: any) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (val: any) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <>
      <div>
      <Helmet>
                <title>{`${selectedSymbol.name}/IRR - Real Prices for ${selectedPairname?.name} to Rial Rate Chart - Riazoom.com`}</title>
                <meta
                  name="description"
                  content="Discover the best exchange rates with our real-time currency comparison tool. Compare live prices across multiple platforms and save money on every transaction."
                />
                <meta
                  name="keywords"
                  content="real-time currency comparison, currency prices, exchange rates, currency comparison tool, best exchange rates"
                />
                <meta name="robots" content="index, follow" />
                <link
                  href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.css"
                  rel="stylesheet"
                />
                <link rel="canonical" href="https://www.rialzoom.com" />
              </Helmet>
        <div className="bg-anta-dark text-white text-center h-60 flex justify-center items-center ">
          <div className="container max-w-screen-xl">
          <div className="text-center flex w-1/2  items-center h-full  ">
            <div className="flex items-center w-2/4">
              <img
                src={`../flags/png250px/${selectedSymbol!.name.replace(
                  /\/|\s/g,
                  "-"
                )}.png`}
                className="w-[80px] h-[80px] inline-block shrink-0 rounded-3xl"
                alt=""
              />
              <div className="ml-5">
                <h2 className="text-2xl font-bold mb-4">
                  {selectedPairname?.name} ( {selectedSymbol!.name} )
                </h2>
              </div>
            </div>
          </div>
          </div>
        </div>
        {isLoading ? (
          <PuffLoader
            color={"#4562c0"}
            loading={true}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="block mx-auto m-0"
          />
        ) : (
          <div className="flex bg-slate-50">
          <div className="container max-w-screen-xl mx-auto  bg-slate-50">
            <div className="flex flex-row   py-8  items-stretch justify-start ">

              <div className="w-3/4 h-auto">
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={seriesData}
                  data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500"]'
                  id={"1"}
                  className="apex-charts"
                  type="line"
                  height={350}
                />
              </div>
              <div className="w-1/4 flex flex-col justify-start items-center  pl-6">
                <div className="flex flex-row w-full items-center"></div>
                <div className="flex flex-col items-center mt-5 justify-between w-full">
                  <div className="flex flex-row items-center justify-between w-full mb-2">
                    <p className="mr-2 w-1/4">From</p>
                    <Flatpickr
                      options={{
                        dateFormat: "Y-m-d",
                      }}
                      value={startDate}
                      onChange={(date: Date[]) => setStartDate(date[0])}
                      placeholder="Select Date"
                      className="form-input w-full border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="mr-2 w-1/4">To</p>
                    <Flatpickr
                      options={{
                        dateFormat: "Y-m-d",
                      }}
                      value={endDate}
                      onChange={(date: Date[]) => setEndDate(date[0])}
                      placeholder="Select Date"
                      className="form-input w-full border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                  </div>
                </div>

                <div className="mt-4  flex w-full justify-end">
                  <Button color="blue" onClick={handleApply}>
                    Find
                  </Button>
                </div>
              </div>
            </div>
          </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SymbolChart;
