import axios from "axios";
export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const apiurl = process.env.REACT_APP_API_URL;


export const getData = async () => {
  try {
    const url = `${apiurl}/getdata`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error("Error loading data:", error);
  }
};

export const getSymbolData = async (symbolname: string,start:Date,end:Date,references:string[]) => {
  try {
    const url = `${apiurl}/datatable/byexsymname`;
    const localstartDate = formatDate(start);
    const localendDate = formatDate(end);
    const response = await axios.post(url, {
      startdate: localstartDate,
      enddate: localendDate,
      symbol: symbolname,
      references:references
    });
    return response.data?.data?.data;
  } catch (error) {
    console.error("Error loading data:", error);
  }
};

export const getExchangeNames = async () => {
  try {
    const url = `${apiurl}/exchangeNames`;

    const response = await axios.get(url);

    return response.data.data.exchangeNames;
  } catch (error) {
    console.error("Error loading data:", error);
  }
};
export const getPairNames = async () => {
  try {
    const url = `${apiurl}/pairNames`;

    const response = await axios.get(url);

    return response.data.data.pairNames;
  } catch (error) {
    console.error("Error loading data:", error);
  }
};