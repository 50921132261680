import { configureStore } from '@reduxjs/toolkit'
import { exchangesNamesReducer } from './state/exchangenames'
import { pairNamesReducer } from './state/pairnames'

export const store = configureStore({
  reducer: {
   exchanenames:exchangesNamesReducer,
   pairnames:pairNamesReducer
  },
  devTools: true
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch