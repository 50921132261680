import React from 'react';

interface ColorCircleProps {
  isActive: boolean;
}

const ColorCircle: React.FC<ColorCircleProps> = ({ isActive }) => {
  return (
    <div
      className={`w-5 h-5 rounded-full ${isActive ? 'bg-a-green' : 'bg-red-500'}`}
    ></div>
  );
};

export default ColorCircle;
