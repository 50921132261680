import * as React from "react";
import { Button, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import { Settings } from "lucide-react";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { selectAllExchangeNames } from "../state/exchangenames";
import { selectAllPairNames } from "../state/pairnames";
import LocalStorageService from "../services/localstorage";
import { Option } from "../common/types";

interface ExchangeSelectProps {
  onApplySettings: (references: MultiValue<Option>, symbols: MultiValue<Option>, sort: SingleValue<Option>) => void;
}

export const ExchangeSelect : React.FC<ExchangeSelectProps> = ({ onApplySettings }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedReferences, setSelectedReferences] = useState<MultiValue<Option>>([]);
  const [selectedSymbols, setSelectedSymbols] = useState<MultiValue<Option>>([]);
  const [selectedSort, setSelectedSort] = useState<SingleValue<Option>>(null);

  const refSettingStorage = new LocalStorageService<MultiValue<Option>>('refconfig');
  const symbolSettingStorage = new LocalStorageService<MultiValue<Option>>('symbolconfig');
  const sortSettingStorage = new LocalStorageService<SingleValue<Option>>('sortconfig');

  const exchangeNamesOptions: Option[] = [{ label: "All", value: "All" }];
  const pairNamesOptions: Option[] = [{ label: "All", value: "All" }];
  const exchangeNames = useSelector(selectAllExchangeNames);
  const pairNames = useSelector(selectAllPairNames);

  exchangeNames.forEach((item) => {
    exchangeNamesOptions.push({
      label: item.name,
      value: item._id,
    });
  });

  pairNames.forEach((item) => {
    pairNamesOptions.push({
      label: item.name,
      value: item._id,
    });
  });

  const sortOptions: Option[] = [
    { label: "Default", value: "priority" },
    { label: "Symbol Name", value: "pairSymbol" },
    { label: "References", value: "exchangeName" },
  ];

  const customStyles = {
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  useEffect(() => {
    const storedReferences = refSettingStorage.getItem();
    if (storedReferences && storedReferences.length > 0) {
      setSelectedReferences(storedReferences);
    } else {
      setSelectedReferences([exchangeNamesOptions[0]]);
    }

    const storedSymbols = symbolSettingStorage.getItem();
    if (storedSymbols && storedSymbols.length > 0) {
      setSelectedSymbols(storedSymbols);
    } else {
      setSelectedSymbols([pairNamesOptions[0]]);
    }

    const storedSort = sortSettingStorage.getItem();
    if (storedSort) {
      setSelectedSort(storedSort);
    } else {
      setSelectedSort(sortOptions[0]);
    }
    
  }, []);

  function handleReferences(newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>): void {
    setSelectedReferences(newValue);
  }

  function handleSymbols(newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>): void {
    setSelectedSymbols(newValue);
  }

  function handleSort(newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>): void {
    setSelectedSort(newValue);
  }

  function handleApply(): void {
    const referencesToStore = selectedReferences.length > 0 ? selectedReferences : [exchangeNamesOptions[0]];
    const symbolsToStore = selectedSymbols.length > 0 ? selectedSymbols : [pairNamesOptions[0]];
    const sortToStore = selectedSort ? selectedSort : sortOptions[0];

    refSettingStorage.setItem(referencesToStore);
    symbolSettingStorage.setItem(symbolsToStore);
    sortSettingStorage.setItem(sortToStore);
    setSelectedReferences(referencesToStore)
    setSelectedSymbols(symbolsToStore);
    setSelectedSort(sortToStore);

    onApplySettings(referencesToStore, symbolsToStore, sortToStore);

    setOpenModal(false);
  }

  return (
    <>
      <Settings
        onClick={() => setOpenModal(true)}
        className="mr-3 cursor-pointer"
        color="white"
        size={32}
      />
      <Modal
        show={openModal}
        content="inner"
        size="3xl"
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>Settings</Modal.Header>
        <Modal.Body>
          <div className="flex items-center ">
            <p className="w-1/4">Select Reference :</p>
            <div className="space-y-6 w-3/4">
              <Select
                className="border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                id="choices-multiple-default"
                data-choices
                name="choices-multiple-default"
                defaultValue={selectedReferences}
                isMulti
                options={exchangeNamesOptions}
                menuPortalTarget={document.body}
                styles={customStyles}
                onChange={handleReferences}
                value={selectedReferences}
              />
            </div>
          </div>
          <div className="flex items-center mt-5 ">
            <p className="w-1/4">Select Symbol :</p>
            <div className="space-y-6 w-3/4">
              <Select
                className="border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                id="choices-multiple-default"
                data-choices
                name="choices-multiple-default"
                defaultValue={selectedSymbols}
                isMulti
                options={pairNamesOptions}
                menuPortalTarget={document.body}
                styles={customStyles}
                onChange={handleSymbols}
                value={selectedSymbols}
              />
            </div>
          </div>
          <div className="flex items-center mt-5">
            <p className="w-1/4">Sort By :</p>
            <div className="space-y-6 w-3/4">
              <Select
                className="border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                id="choices-multiple-default"
                isSearchable={false}
                name="choices-multiple-default"
                defaultValue={selectedSort}
                options={sortOptions}
                menuPortalTarget={document.body}
                styles={customStyles}
                onChange={handleSort}
                value={selectedSort}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="blue" onClick={handleApply}>
            Apply
          </Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
