import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";

const Contact = () => {
  const [result, setResult] = useState("");
  const [isSent, setIsSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data:any) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("message", data.message);
    formData.append("access_key", "15919da8-96fd-4f82-a8d8-4f10155c7c8f");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const responseData = await response.json();

    if (responseData.success) {
      setResult("Form Submitted Successfully");
      setIsSent(true);
      reset();
    } else {
      console.log("Error", responseData);
      setResult(responseData.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact us - Riazoom.com</title>
        <meta
          name="description"
          content="Discover the best exchange rates with our real-time currency comparison tool. Compare live prices across multiple platforms and save money on every transaction."
        />
        <meta
          name="keywords"
          content="real-time currency comparison, currency prices, exchange rates, currency comparison tool, best exchange rates, rial price"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.rialzoom.com" />
      </Helmet>
      <div className="bg-anta-dark text-white text-center min-h-60 flex items-center justify-center">
        <h2 className="text-2xl font-bold mb-4">Contact us</h2>
      </div>
      <div className="flex-1 bg-slate-50">
        <div className="flex justify-center items-start bg-slate-50 w-full py-10 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-5xl">
            {isSent && (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6"
                role="alert"
              >
                <strong className="font-bold">Success!</strong>
                <span className="block sm:inline"> Your message has been sent.</span>
              </div>
            )}

            {!isSent && (
               <form
               onSubmit={handleSubmit(onSubmit)}
               className="bg-white shadow-md rounded px-8 pt-6 pb-8 w-full"
             >
               <div className="mb-4 flex flex-col md:flex-row md:items-center">
                 <label
                   className="md:w-1/4 text-gray-700 text-sm font-bold mb-2 md:mb-0"
                   htmlFor="name"
                 >
                   Name
                 </label>
                 <input
                   className={`w-full md:w-3/4 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                     errors.name ? "border-red-500 mr-4" : ""
                   }`}
                   id="name"
                   type="text"
                   placeholder="Your Name"
                   {...register("name", { required: "Name is required" })}
                 />
                 {errors.name && (
                   <p className="text-red-500 text-xs italic">{errors.name.message as string}</p>
                 )}
               </div>
               <div className="mb-4 flex flex-col md:flex-row md:items-center">
                 <label
                   className="md:w-1/4 text-gray-700 text-sm font-bold mb-2 md:mb-0"
                   htmlFor="email"
                 >
                   Email
                 </label>
                 <input
                   className={`w-full md:w-3/4 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                     errors.email ? "border-red-500 mr-4" : ""
                   }`}
                   id="email"
                   type="email"
                   placeholder="Your Email"
                   {...register("email", {
                     required: "Email is required",
                     pattern: {
                       value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                       message: "Invalid email address",
                     },
                   })}
                 />
                 {errors.email && (
                   <p className="text-red-500 text-xs italic">{errors.email.message as string}</p>
                 )}
               </div>
               <div className="mb-6 flex flex-col md:flex-row md:items-center">
                 <label
                   className="md:w-1/4 text-gray-700 text-sm font-bold mb-2 md:mb-0"
                   htmlFor="message"
                 >
                   Message
                 </label>
                 <textarea
                   className={`w-full md:w-3/4 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                     errors.message ? "border-red-500 mr-4" : ""
                   }`}
                   id="message"
                   placeholder="Your Message"
                   rows={6}
                   {...register("message", { required: "Message is required" })}
                 ></textarea>
                 {errors.message && (
                   <p className="text-red-500 text-xs italic">{errors.message.message as string}</p>
                 )}
               </div>
               <div className="flex items-center justify-center">
                 <button
                   className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                   type="submit"
                 >
                   Submit
                 </button>
               </div>
             </form>

            )}
           
         
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
