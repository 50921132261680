import { Outlet } from "react-router-dom";
import Header from "./layout/header";
import { Footer } from "./layout/footer";
import { selectAllExchangeNames } from "../state/exchangenames";
import { useSelector } from "react-redux";
import { selectAllPairNames } from "../state/pairnames";

const Layout = () => {
  return (
    <>
      <Header />

      <section
        id="maincontent"
        className="flex-1 bg-slate-50 items-center justify-center"
      >
        <Outlet />
      </section>
      <div
        id="footer"
        className="bg-anta-dark text-white p-4 text-center min-h-60  "
      >
        <Footer />
      </div>
    </>
  );
};

export default Layout;
