import { useState, useEffect, useRef } from "react";
import { PuffLoader } from "react-spinners";
import * as React from "react";
import { Link } from "react-router-dom";
import { insertCommas, getCurrentDateTime } from "../utils";
import { ExchangeSelect } from "./ExchangeSelect";
import { Tooltip } from "flowbite-react";
import { MultiValue, SingleValue } from "react-select";
import { Option } from "../common/types";
import LocalStorageService from "../services/localstorage";
import { Search } from "lucide-react";

interface MainDataTableProps {
  loading: boolean;
  data: any[];
  lastupdate: Date;
}

const rowsPerPage = 8;

const MainDataTable: React.FC<MainDataTableProps> = ({
  loading,
  data,
  lastupdate,
}) => {
  const [sortedData, setSortedData] = useState<any>([]);
  const [sortConfig, setSortConfig] = useState({
    key: { label: "Default", value: "priority" },
    ascending: true,
  });
  const exchangeNamesOptions: Option[] = [{ label: "All", value: "All" }];
  const pairNamesOptions: Option[] = [{ label: "All", value: "All" }];
  const sortOptions: Option[] = [
    { label: "Default", value: "priority" },
    { label: "Symbol Name", value: "pairSymbol" },
    { label: "References", value: "exchangeName" },
  ];

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchClick = () => {
    setIsSearchVisible((prev) => !prev);
    if (!isSearchVisible && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleSearchInputChange = (value: string) => {
    setSearchTerm(value);
    filterData(value);
  };

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [next, setNext] = useState(rowsPerPage);

  const [selectedReferences, setSelectedReferences] = useState<
    MultiValue<Option>
  >([]);
  const [selectedSymbols, setSelectedSymbols] = useState<MultiValue<Option>>(
    []
  );
  const [selectedSort, setSelectedSort] = useState<SingleValue<Option>>(
    sortOptions[0]
  );

  const refSettingStorage = new LocalStorageService<MultiValue<Option>>(
    "refconfig"
  );
  const symbolSettingStorage = new LocalStorageService<MultiValue<Option>>(
    "symbolconfig"
  );
  const sortSettingStorage = new LocalStorageService<SingleValue<Option>>(
    "sortconfig"
  );

  useEffect(() => {
    applyFiltersAndSort();
  }, [
    data,
    sortConfig,
    filters,
    selectedSymbols,
    selectedSort,
    selectedReferences,
  ]);

  useEffect(() => {
    const storedReferences = refSettingStorage.getItem();
    if (storedReferences && storedReferences.length > 0) {
      setSelectedReferences(storedReferences);
    } else {
      setSelectedReferences([exchangeNamesOptions[0]]);
    }

    const storedSymbols = symbolSettingStorage.getItem();
    if (storedSymbols && storedSymbols.length > 0) {
      setSelectedSymbols(storedSymbols);
    } else {
      setSelectedSymbols([pairNamesOptions[0]]);
    }

    const storedSort = sortSettingStorage.getItem();
    if (storedSort) {
      setSortConfig({
        key: {
          label: storedSort.label!.toString(),
          value: storedSort.value!.toString(),
        },
        ascending: true,
      });
    } else {
      setSortConfig(sortConfig);
    }
  }, []);

  const handleMoreImage = () => {
    setNext(next + rowsPerPage);
  };

  let counter = 0;
  const minMaxPrices: Record<string, { min: number; max: number }> = {};
  data.forEach((pair) => {
    const prices = pair.exchanges.map(
      (exchange: any) => exchange.lastSellPriceToday || 0
    );
    minMaxPrices[pair._id] = {
      min: Math.min(...prices),
      max: Math.max(...prices),
    };
  });

  function applyFiltersAndSort(): void {
    let updatedData = data.map((item) => ({
      ...item,
      exchanges: [...item.exchanges],
    }));

    if (
      selectedReferences.length > 0 &&
      !selectedReferences.some((item) => item.value === "All")
    ) {
      updatedData = updatedData.map((item) => ({
        ...item,
        exchanges: item.exchanges.filter((exchange: any) =>
          selectedReferences.some(
            (ref) => ref.value === exchange.exchangeName._id
          )
        ),
      }));
    }

    if (
      selectedSymbols.length > 0 &&
      !selectedSymbols.some((item) => item.value === "All")
    ) {
      updatedData = updatedData.filter((item) =>
        selectedSymbols.some((symbol) => symbol.value === item.pair._id)
      );
    }

    const sorted = updatedData.sort((a, b) => {
      if (!a[sortConfig.key.value].name) {
        if (a[sortConfig.key.value] < b[sortConfig.key.value])
          return sortConfig.ascending ? -1 : 1;
        if (a[sortConfig.key.value] > b[sortConfig.key.value])
          return sortConfig.ascending ? 1 : -1;
      } else {
        if (a[sortConfig.key.value].name < b[sortConfig.key.value].name)
          return sortConfig.ascending ? -1 : 1;
        if (a[sortConfig.key.value].name > b[sortConfig.key.value].name)
          return sortConfig.ascending ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sorted);
  }
  const filterData = (term: string) => {
    if (!term) {
      setFilteredData([]);
      return;
    }

    const filtered = sortedData.filter((item: any) => {
      const pairName = item.pair.name.toLowerCase();
      const exchangeNames = item.exchanges.map((exchange: any) =>
        exchange.exchangeName.name.toLowerCase()
      );
      const symbolName = item.pairSymbol.name.toLowerCase();

      return (
        pairName.includes(term.toLowerCase()) ||
        exchangeNames.some((name: any) => name.includes(term.toLowerCase())) ||
        symbolName.includes(term.toLowerCase())
      );
    });

    setFilteredData(filtered);
  };

  const toggleSortOrder = (key: Option) => {
    setSortConfig((prevConfig) => ({
      key: { label: key.label!.toString(), value: key.value!.toString() },
      ascending:
        prevConfig.key.value === key.value ? !prevConfig.ascending : true,
    }));
  };

  function handleApplySettings() {
    const storedReferences = refSettingStorage.getItem();
    if (storedReferences && storedReferences.length > 0) {
      setSelectedReferences(storedReferences);
    } else {
      setSelectedReferences([exchangeNamesOptions[0]]);
    }

    const storedSymbols = symbolSettingStorage.getItem();
    if (storedSymbols && storedSymbols.length > 0) {
      setSelectedSymbols(storedSymbols);
    } else {
      setSelectedSymbols([pairNamesOptions[0]]);
    }

    const storedSort = sortSettingStorage.getItem();
    if (storedSort) {
      if (storedSort.value !== selectedSort?.value) {
        setSelectedSort(storedSort);
        toggleSortOrder(storedSort!);
      }
    } else {
      setSelectedSort(sortOptions[0]);
      toggleSortOrder(storedSort!);
    }
  }

  const dataToRender = filteredData.length > 0 ? filteredData : sortedData;
  return (
    <>
      <div className="bg-anta-dark text-white text-center min-h-60 flex items-center justify-center  "></div>

      <div className="text-center ">
        {data.length === 0 ? (
          <PuffLoader
            color={"#4562c0"}
            loading={true}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="block mx-auto m-0 mt-8"
          />
        ) : (
          <div className="container mx-auto mt-8 -top-52  relative max-w-screen-xl ">
            <div className="flex  container items-center justify-between mb-4 ">
              <div className="flex-grow">
                <span className="text-dark align-baseline text-center items-end  flex-row flex">
                  <h1 className="text-xl text-white">
                    Explore and Compare Real Iranian Rial Prices Across Various
                    Platforms
                  </h1>
                </span>
                <span className="text-sm text-white text-center items-end flex-row flex ">
                  Last Update: {getCurrentDateTime(lastupdate)} - All prices are
                  in Iranian Toman (1 Toman = 10 Rials)
                </span>
              </div>
              <div className="flex-shrink flex items-center">
                <div
                  className={`transition-all duration-500 ease-in-out ${
                    isSearchVisible ? "w-48 opacity-100" : "w-0 opacity-0"
                  } overflow-hidden ml-2 mr-2 rounded-md`}
                >
                  <input
                    type="text"
                    ref={inputRef}
                    className="search-input p-2 border border-gray-300 rounded-md h-9"
                    value={searchTerm}
                    onChange={(e) => handleSearchInputChange(e.target.value)}
                    
                  />
                </div>
                <Search
                  className="mr-3 cursor-pointer"
                  color="white"
                  size={32}
                  onClick={handleSearchClick}
                ></Search>
                <ExchangeSelect onApplySettings={handleApplySettings} />
              </div>
            </div>
            <div className="overflow-x-auto shadow-2xl rounded-2xl">
              <table className="w-full whitespace-nowrap bg-white divide-y divide-gray-200 rounded-lg overflow-hidden border-neutral-200">
                <thead className="bg-gray-50 ">
                  <tr className="text-xs font-medium text-gray-500 uppercase tracking-wider ">
                    <th className="py-4 px-4 text-center ">#</th>
                    <th className="py-4 px-4 text-center">
                      <div className="flex items-center flex-row justify-center">
                        <span>Symbol</span>
                        <div
                          onClick={() => toggleSortOrder(sortOptions[1])}
                          className=" ml-3 group cursor-pointer"
                        >
                          <svg
                            className="h-4 w-4 text-black group-hover:text-green-900"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M3 9l4-4l4 4m-4 -4v14" />
                            <path d="M21 15l-4 4l-4-4m4 4v-14" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th className="py-3 px-4 text-center">
                      <div className="flex items-center flex-row justify-center">
                        <span>References</span>
                        <div className="group cursor-pointer">
                          <Tooltip
                            animation="duration-500"
                            content="References of price"
                          >
                            <svg
                              className="ml-3 h-4 w-4 text-black  group-hover:text-green-900"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10" />{" "}
                              <line x1="12" y1="16" x2="12" y2="12" />{" "}
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                            </svg>
                          </Tooltip>
                        </div>
                        <div
                          onClick={() => toggleSortOrder(sortOptions[2])}
                          className=" ml-3 group cursor-pointer"
                        >
                          <svg
                            className="h-4 w-4 text-black group-hover:text-green-900"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M3 9l4-4l4 4m-4 -4v14" />
                            <path d="M21 15l-4 4l-4-4m4 4v-14" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th className="py-3 px-4 text-center">
                      <div className="flex items-center flex-row justify-center">
                        <span>Lowest</span>
                        <Tooltip
                          animation="duration-500"
                          content="Lowest price over the last 24 hours"
                        >
                          <svg
                            className="ml-3 h-4 w-4 text-black  group-hover:text-green-900"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="10" />{" "}
                            <line x1="12" y1="16" x2="12" y2="12" />{" "}
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                          </svg>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="py-3 px-4 text-center">
                      <div className="flex items-center flex-row justify-center">
                        <span>Highest</span>
                        <Tooltip
                          animation="duration-500"
                          content="Highest price over the last 24 hours"
                        >
                          <svg
                            className="ml-3 h-4 w-4 text-black  group-hover:text-green-900"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="10" />{" "}
                            <line x1="12" y1="16" x2="12" y2="12" />{" "}
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                          </svg>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="py-3 px-4 text-center">
                      <div className="flex items-center flex-row justify-center">
                        <span>Price</span>
                        <Tooltip
                          animation="duration-500"
                          content="Current price. The highest price for each symbol is displayed in red, and the lowest price for each symbol is displayed in green."
                        >
                          <svg
                            className="ml-3 h-4 w-4 text-black  group-hover:text-green-900"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="10" />{" "}
                            <line x1="12" y1="16" x2="12" y2="12" />{" "}
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                          </svg>
                        </Tooltip>
                      </div>
                    </th>
                    <th className="py-3 px-4 text-center">
                      <div className="flex items-center flex-row justify-center">
                        <span>24H Change %</span>
                        <Tooltip
                          animation="duration-500"
                          content="Percentage change in price over the last 24 hours"
                        >
                          <svg
                            className="ml-3 h-4 w-4 text-black  group-hover:text-green-900"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="10" />{" "}
                            <line x1="12" y1="16" x2="12" y2="12" />{" "}
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                          </svg>
                        </Tooltip>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {dataToRender
                    .slice(0, next)
                    .map((pair: any, pairIndex: any) =>
                      pair.exchanges.map(
                        (exchange: any, exchangeIndex: any) => (
                          <tr
                            key={`${pair._id}-${exchangeIndex}-${pairIndex}`}
                            className="transition duration-700 ease-in-out hover:bg-blue-50 border-t-2"
                          >
                            {exchangeIndex === 0 && (
                              <td
                                rowSpan={pair.exchanges.length}
                                className="border-r-2 py-3 px-4 text-center bg-white"
                              >
                                {(counter = counter + 1)}
                              </td>
                            )}
                            {exchangeIndex === 0 && (
                              <td
                                rowSpan={pair.exchanges.length}
                                className="border-r-2 py-3 px-4 text-center bg-white cursor-pointer"
                              >
                                <Link
                                  to={`/symbol/${pair.pairSymbol.name}`}
                                  className="text-sm text-gray-500 hover:text-green-9 text-center"
                                  state={{
                                    symbol: pair.pairSymbol,
                                    selectedReferences: selectedReferences,
                                  }}
                                >
                                  <div className="flex flex-row items-center justify-start">
                                    <div className="inline-block shrink-0 rounded-2xl me-3">
                                      <img
                                        src={`../flags/png250px/${pair._id.replace(
                                          /\/|\s/g,
                                          "-"
                                        )}.png`}
                                        className="w-[40px] h-[40px] inline-block shrink-0 rounded-2xl"
                                        alt=""
                                      />
                                    </div>
                                    <div className="flex flex-col items-start">
                                      <span className="text-base">
                                        {" "}
                                        {pair.pair.name}
                                      </span>

                                      {pair._id}
                                    </div>
                                  </div>
                                </Link>
                              </td>
                            )}
                            <td className="py-3 px-4 text-center">
                              {exchange.exchangeName.name}
                            </td>
                            <td className="py-3 px-4 text-center">
                              {insertCommas(
                                (exchange.minSellPriceToday || 0).toString()
                              )}
                            </td>
                            <td className="py-3 px-4 text-center">
                              {insertCommas(
                                (exchange.maxSellPriceToday || 0).toString()
                              )}
                            </td>
                            <td className="py-3 px-4 text-center">
                              <span
                                className={`${
                                  exchange.lastSellPriceToday ===
                                  minMaxPrices[pair._id]?.min
                                    ? "text-green-600"
                                    : exchange.lastSellPriceToday ===
                                      minMaxPrices[pair._id]?.max
                                    ? "text-red-500"
                                    : "text-black"
                                }`}
                              >
                                {insertCommas(
                                  (exchange.lastSellPriceToday || 0).toFixed()
                                )}
                              </span>
                            </td>
                            <td className="py-3 px-4 text-center">
                              <div
                                className={`flex items-center justify-center ${
                                  exchange.changePercent > 0
                                    ? "text-green-600"
                                    : exchange.changePercent < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                <div className="group">
                                  {exchange.changePercent > 0 ? (
                                    <svg
                                      className="h-5 w-5 text-green-500"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <polyline points="18 15 12 9 6 15" />
                                    </svg>
                                  ) : exchange.changePercent < 0 ? (
                                    <svg
                                      className="h-5 w-5 text-red-500"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                  ) : (
                                    <svg
                                      className="h-5 w-5 text-black"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinejoin="round"
                                    >
                                      <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                  )}
                                </div>
                                <span className="ml-1">
                                  {Number(exchange.changePercent).toFixed(2)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    )}
                </tbody>
              </table>
            </div>

            {next < dataToRender.length && (
              <button
                onClick={handleMoreImage}
                className="mt-4 bg-transparent hover:bg-anta-dark text-gray-500 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
              >
                Load more
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MainDataTable;
