import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExchangeNames,
  selectAllExchangeNames,
} from "../state/exchangenames";
import ColorCircle from "../components/ColorCircle";
import { AppDispatch, RootState } from "../store";
import { Helmet } from "react-helmet-async";

const References: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const exchangeNamesStatus = useSelector(
    (state: RootState) => state.exchanenames.status
  );
  const exchangeNames = useSelector(selectAllExchangeNames);

  if (exchangeNames.length === 0) {
    if (exchangeNamesStatus === "idle") {
      dispatch(fetchExchangeNames());
    }
  }
  return (
    <>
      <Helmet>
        <title>{`References list for rates - Riazoom.com`}</title>
        <meta
          name="description"
          content="Discover the best exchange rates with our real-time currency comparison tool. Compare live prices across multiple platforms and save money on every transaction."
        />
        <meta
          name="keywords"
          content="real-time currency comparison, currency prices, exchange rates, currency comparison tool, best exchange rates, rial price"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.rialzoom.com" />
      </Helmet>
      <div className="bg-anta-dark text-white text-center min-h-60 flex items-center justify-center">
        <h2 className="text-2xl font-bold mb-4">References</h2>
      </div>
      <div className="flex bg-slate-50">
        <div className="container mx-auto max-w-screen-xl ">
          <div className="w-3/4 m-auto mt-10 mb-10">
            <table className="table-auto w-full border-collapse shadow-xl rounded-xl">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-gray-500 uppercase font-bold text-sm">
                    Name
                  </th>
                  <th className="px-6 py-3 bg-gray-100 text-gray-500 uppercase font-bold text-sm">
                    URL
                  </th>
                  <th className="px-6 py-3 bg-gray-100 text-gray-500 uppercase font-bold text-sm">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {exchangeNames.map((reference) => (
                  <tr key={reference._id}>
                    <td className="border-collapse border-b-2 px-6 py-4">
                      {reference.name}
                    </td>
                    <td className="border-collapse border-b-2 px-6">
                      <a
                        href={reference.URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {reference.URL}
                      </a>
                    </td>
                    <td className="border-collapse border-b-2 px-6 py-4">
                      <div className="flex justify-center items-center h-full">
                        <ColorCircle isActive={reference.isActive} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default References;
