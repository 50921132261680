export function getCurrentDateTime(date:Date): string {
    // Get the current date and time
    const currentDateTime = date;

    // Extract year, month, day, hour, and minute
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    const day = String(currentDateTime.getDate()).padStart(2, '0');
    const hours = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');

    // Format the date and time as "yyyy/mm/dd - HH:mm"
    const formattedDateTime = `${year}/${month}/${day} - ${hours}:${minutes}`;

    return formattedDateTime;
}

export function formatDateTime(datestring: string): string {
    const date: Date = new Date(datestring);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} - ${hours}:${minutes}`;
}
export function removeCharacterFromString(
    inputString: string,
    characterToRemove: string
  ): string {
    let result = "";
  
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] !== characterToRemove) {
        result += inputString[i];
      }
    }
  
    return result;
  }
  export function insertCommas(inputString: string): string {
    const length = inputString.length;
  
    if (length <= 1) {
      return inputString;
    }
  
    let result = "";
  
    for (let i = length - 1, count = 0; i >= 0; i--) {
      result = inputString[i] + result;
      count++;
  
      if (count === 3 && i !== 0) {
        result = "," + result;
        count = 0;
      }
    }
  
    return result;
  }