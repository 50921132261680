// LocalStorageService.ts
class LocalStorageService<T> {
    private key: string;
  
    constructor(key: string) {
      this.key = key;
    }
  
    getItem(): T | null {
      const item = localStorage.getItem(this.key);
      return item ? JSON.parse(item) : null;
    }
  
    setItem(value: T): void {
      localStorage.setItem(this.key, JSON.stringify(value));
    }
  
    removeItem(): void {
      localStorage.removeItem(this.key);
    }
  }
  
  export default LocalStorageService;
  