import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
 
    EntityId,
  } from "@reduxjs/toolkit";
  
  import {
  getExchangeNames
  } from "../services/maindata";
 
  import { RootState } from "../store";
import { IExchangeNames } from "../common/types";
  
  const exchangeNamesAdapter = createEntityAdapter<IExchangeNames, EntityId>({
    selectId: (e: any) => e._id,
    sortComparer: (a, b) => a._id.localeCompare(b._id),
  });
  
  const initialState = exchangeNamesAdapter.getInitialState({
    status: "idle",
    error: null,
  });
  
  export const fetchExchangeNames = createAsyncThunk(
    "exchangeNames/fetchExchangeNames",
    async () => {
      const response = await getExchangeNames();
      return response
    }
  );
  
  const exchangeNamesSlice = createSlice({
    name: "exchangeNames",
    initialState: initialState,
    reducers: {
 
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchExchangeNames.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchExchangeNames.fulfilled, (state, action) => {
          state.status = "idle";
          exchangeNamesAdapter.upsertMany(state, action.payload);
        })
        .addCase(fetchExchangeNames.rejected, (state, action) => {
          state.status = "failed";
          // state.error = action.error.message;
        })
     
    },
  });

  export const { selectAll: selectAllExchangeNames, selectById: selectExchangeNameById } =
  exchangeNamesAdapter.getSelectors((state: RootState) => state.exchanenames);
  
  export const exchangesNamesReducer = exchangeNamesSlice.reducer;
  