export const Footer = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center  text-center">
        <div className="flex  mx-auto justify-around  pt-6 pb-6">
          <div className="w-1/4 overflow-auto text-left">
            <h3 className=" font-semibold  mb-2">RIALZOOM.COM</h3>
            <ul className="text-slate-300  text-sm">
              <li>Methodolgy</li>
              <li>Support</li>
              <li>Our API</li>
              <li>Rate Comparison</li>
            </ul>
          </div>
          <div className="w-1/4 overflow-auto text-left">
            <h3 className=" font-semibold mb-2">LEGALS</h3>
            <ul className="text-slate-300 text-sm ">
              <li>Terms of Service</li>
              <li>Privacy Policy</li>
            </ul>
          </div>
          <div className="w-1/4 overflow-auto text-left">
            <h3 className=" font-semibold mb-2">DISCLAIMER</h3>
            <span className="text-slate-300 text-sm  ">
              We function solely as a platform for displaying prices sourced
              from various informational outlets and aim to provide price
              comparisons. Consequently, we do not engage in any profit-making
              activities or ownership claims, and we hereby disclaim any
              associated responsibilities.
            </span>
          </div>
          <div className="w-1/4  overflow-auto text-center flex flex-col items-center justify-start">
            <h3 className=" font-semibold mb-2">FOLLOW US</h3>
            <span className="flex">
              <img
                src="telegram.png"
                alt="Telegram Icon"
                className="w-10 h-10 mr-2"
              />
              <img
                src="instagram.png"
                alt="Instagram Icon"
                className="w-10 h-10"
              />
            </span>
          </div>
        </div>
        <h4 className="text-sm mt-8 text-slate-300 ">
          Copyright © 2018 - 2024 Rialzoom.com. All rights reserved
        </h4>
      </div>
    </div>
  );
};
