import { FC, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import MainDataTable from "../components/MainDataTable";
import { getData } from "../services/maindata";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeNames } from "../state/exchangenames";
import { fetchPairNames } from "../state/pairnames";
export interface Ipair {
  _id: string;
  pairSymbol: string;
  pair: string;
  exchangeName: string;
  buyPrice: string;
  sellPrice: string;
  timeDate: Date;
}
const Home: FC = () => {
  const [data, setData] = useState<Ipair[]>([]);
  const [loadingData, setloadingData] = useState(false);
  const [lastupdate, setlastUpdate] = useState<Date>(new Date());
  const dispatch = useDispatch<AppDispatch>();
  const exchangeNamesStatus = useSelector(
    (state: RootState) => state.exchanenames.status
  );
  const pairNamesStatus = useSelector(
    (state: RootState) => state.pairnames.status
  );
  useEffect(() => {
    if (exchangeNamesStatus === "idle") {
      dispatch(fetchExchangeNames());
    }
    if (pairNamesStatus === "idle") {
      dispatch(fetchPairNames());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData();
      setData([]);
      setData(response!.data.data);
      setlastUpdate(new Date(response!.data.lastupdate.toString()));
      setloadingData(false);
    };

    fetchData();
  }, []);

  ReactGA.send({ hitType: "pageview", page: "/", title: "Home page" });

  return (
    <>
      <MainDataTable
        loading={loadingData}
        data={data}
        lastupdate={lastupdate}
      />
    </>
  );
};

export default Home;
