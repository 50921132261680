import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './pages/Layout'
import Home from './pages/Home'
import Contact from './pages/Contact'
import References from './pages/References'
import SymbolChart from './components/SymbolChart'
import NoPage from './pages/NoPage'


const AppRouter = () => {
  return (
    <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="References" element={<References />} />
      <Route
        path="symbol/:id"
        element={<SymbolChart symbol={{ _id: "", name: "" }} selectedRefrences={[{ label: "All", value: "All" }]} />}
      />
      <Route path="*" element={<NoPage />} />
    </Route>
  </Routes>
  )
}

export default AppRouter